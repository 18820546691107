
import state from './moduleCalendarState.js'
import mutations from './moduleCalendarMutations.js'
import actions from './moduleCalendarActions.js'
import getters from './moduleCalendarGetters.js'


export default {
  // isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

