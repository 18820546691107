
import state from './moduleSavingState.js'
import mutations from './moduleSavingMutations.js'
import actions from './moduleSavingActions.js'
import getters from './moduleSavingGetters.js'


export default {
  // isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

