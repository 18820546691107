import axios from '@/axios.js'
import store from '../index';
// import router from '../../router/index';

const actions = {
  addSaving({ commit }, value) {  
    axios.post('account/savings', value)
      .then(response => { 
        commit('SET_SAVING', response.data.saving)    
        
        if(store.state.moduleSaving.errors !== null){
          commit('SET_ERRORS', null)  
        } 

      }).catch(error => {

        console.log(error)
        // commit('SET_ERRORS', error.response.data.errors) 
      })
  },

  getSavings({ commit }) { 
    return new Promise((resolve, reject) => {
      axios.get('account/savings')
      .then(res => { 
        // commit('SET_DEBITS', res.data.data) 
        resolve(res)
      }).catch(error => {
  
        console.log(error)
        // commit('SET_ERRORS', error.response.data.errors) 
      }) 
    }) 

  },

  getRecap(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/account/recap-savings')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  findSaving(context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`account/savings/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  editSaving({ commit }, value) {   
    axios.post('account/savings/' + value.id, value.data)
      .then(response => {
        commit('SET_SAVING_UPDATED', response.data.saving)    
        
        if(store.state.moduleSaving.errors !== null){
          commit('SET_ERRORS', null)  
        } 
      
        // router.replace({ name: 'posts' });
  
      }).catch(error => { 
        // commit('SET_ERRORS', error.response.data.errors) 
      })
  },
  
  deleteSaving({ commit }, id) { 
    axios.delete('account/savings/' + id) 
      .then(res => { 
        commit('SET_SAVING_DELETED', id) 
      }).catch(error => { 
        console.log(error)
      })
  }
}

export default actions