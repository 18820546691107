import { createStore } from 'vuex'
import auth from './auth' 
import moduleDebit from './debit/moduleDebit'
import moduleCredit from './credit/moduleCredit'
import moduleSaving from './saving/moduleSaving'
import moduleCalendar from './calendar/moduleCalendar'
import axios from '@/axios.js'

export default createStore({
  getters: {

  },
  mutations: {

  },
  state: {
    app: {
      name: "Personal Finance"
    },
  },
  actions: {
    getBalance(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get('/account/balance')
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
  },  
  modules: {
    auth,
    moduleDebit,
    moduleCredit,
    moduleSaving,
    moduleCalendar,
  }
})
