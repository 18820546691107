const mutations = {
  SET_CALENDARS(state, value) {
    state.calendars = value
  },
  SET_ERRORS (state, value) {
    state.errors = value
  },
  SET_CALENDAR(state, value) {
    state.calendars.unshift(value)  
  },
  SET_CALENDAR_UPDATED(state, value) {
    const index = state.calendars.findIndex(calendar => calendar.id === value.id);
    Vue.set(state.calendars, index, value);    
  },
  SET_CALENDAR_DELETED(state, value) {
    const index = state.calendars.findIndex(calendar => calendar.id === value); 
    state.calendars.splice(index, 1); 
  },
}
export default mutations
