
import state from './moduleCreditState.js'
import mutations from './moduleCreditMutations.js'
import actions from './moduleCreditActions.js'
import getters from './moduleCreditGetters.js'


export default {
  // isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

