import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  // Route if page not found (404)
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import('@/views/404.vue'),
  },
  {
    // Dashboard
    path: '',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/HomeView.vue'),
      },
      {
        path: 'debit',
        name: 'Debit',
        component: function () {
          return import('../views/debit/DebitView.vue')
        },
      },
      {
        path: 'debit/add',
        name: 'debit-add',
        component: function () {
          return import('../views/debit/DebitAdd.vue')
        }
      },
      {
        path: 'debit/edit/:id',
        name: 'debit-edit',
        component: function () {
          return import('../views/debit/DebitEdit.vue')
        }
      },
      {
        path: 'credit',
        name: 'Credit',
        component: function () {
          return import('../views/credit/CreditView.vue')
        },
      },
      {
        path: 'credit/add',
        name: 'credit-add',
        component: function () {
          return import('../views/credit/CreditAdd.vue')
        }
      },
      {
        path: 'credit/edit/:id',
        name: 'credit-edit',
        component: function () {
          return import('../views/credit/CreditEdit.vue')
        }
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: function () {
          return import('../views/calendar/CalendarView.vue')
        }
      },
      {
        path: 'saving',
        name: 'saving',
        component: function () {
          return import('../views/saving/SavingView.vue')
        }
      },
      {
        path: 'saving/edit/:id',
        name: 'saving-edit',
        component: function () {
          return import('../views/saving/SavingEdit.vue')
        }
      },
    ]
  },

  {
    path: '',
    component: () => import('../layouts/AuthLayout.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/LoginView.vue'),
        // meta: {
        //   rule: 'editor'
        // }
      },
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => { 
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.isLoggedIn && store.getters.user) {
      next()
      return
    }
    next('/login')
  }
  next()
})
export default router
