const mutations = {
  SET_SAVINGS(state, value) {
    state.savings = value
  },
  SET_ERRORS (state, value) {
    state.errors = value
  },
  SET_SAVING(state, value) {
    state.savings.unshift(value)  
  },
  SET_SAVING_UPDATED(state, value) {
    const index = state.savings.findIndex(saving => saving.id === value.id);
    Vue.set(state.savings, index, value);    
  },
  SET_SAVING_DELETED(state, value) {
    const index = state.savings.findIndex(saving => saving.id === value); 
    state.savings.splice(index, 1); 
  },
}
export default mutations
