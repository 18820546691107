const mutations = {
  SET_DEBITS(state, value) {
    state.debits = value
  },
  SET_ERRORS (state, value) {
    state.errors = value
  },
  SET_DEBIT(state, value) {
    state.debits.unshift(value)  
  },
  SET_DEBIT_UPDATED(state, value) {
    const index = state.debits.findIndex(debit => debit.id === value.id);
    Vue.set(state.debits, index, value);    
  },
  SET_DEBIT_DELETED(state, value) {
    const index = state.debits.findIndex(debit => debit.id === value); 
    state.debits.splice(index, 1); 
  },
}
export default mutations
