import { createApp } from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Content from './components/Content.vue'
import { setHeaderToken } from './utils/auth'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// Vue Select
import vSelect from 'vue-select'
import '../node_modules/vue-select/dist/vue-select.css'

const token = localStorage.getItem('token');

if (token) { 
  setHeaderToken(token) 
} 

store.dispatch('get_user', token)
.then(() => {
  const app = createApp(App)

  app.component('v-select', vSelect)
  app.component('content', Content)
  app.use(VueSweetalert2)
  app.use(store)
  app.use(router)
  
  app.mount('#app')
}).catch((error) => {
  console.error(error);
})


