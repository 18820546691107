
import state from './moduleDebitState.js'
import mutations from './moduleDebitMutations.js'
import actions from './moduleDebitActions.js'
import getters from './moduleDebitGetters.js'


export default {
  // isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

