const mutations = {
  SET_CREDITS(state, value) {
    state.credits = value
  },
  SET_ERRORS (state, value) {
    state.errors = value
  },
  SET_CREDIT(state, value) {
    state.credits.unshift(value)  
  },
  SET_CREDIT_UPDATED(state, value) {
    const index = state.credits.findIndex(credit => credit.id === value.id);
    Vue.set(state.credits, index, value);    
  },
  SET_CREDIT_DELETED(state, value) {
    const index = state.credits.findIndex(credit => credit.id === value); 
    state.credits.splice(index, 1); 
  },
}
export default mutations
