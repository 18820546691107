import axios from '@/axios.js'
import store from '../index';
// import router from '../../router/index';

const actions = {
  addCalendar({ commit }, value) {  
    axios.post('account/calendar', value)
      .then(response => { 
        commit('SET_CALENDAR', response.data.data) 
        if(store.state.moduleCalendar.errors !== null){
          commit('SET_ERRORS', null)  
        } 

      }).catch(error => {

        console.log(error)
        // commit('SET_ERRORS', error.response.data.errors) 
      })
  },

  getCalendars({ commit }) { 
    return new Promise((resolve, reject) => {
      axios.get('account/calendar')
      .then(res => { 
        // commit('SET_DEBITS', res.data.data) 
        resolve(res)
      }).catch(error => {
  
        console.log(error)
        // commit('SET_ERRORS', error.response.data.errors) 
      }) 
    }) 

  },

  findCalendar(context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`account/calendar/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  editCalendar({ commit }, value) {   
    axios.post('account/calendar/' + value.id, value.data)
      .then(response => {
        commit('SET_CALENDAR_UPDATED', response.data)    
        
        if(store.state.moduleCalendar.errors !== null){
          commit('SET_ERRORS', null)  
        } 
      
        // router.replace({ name: 'posts' });
  
      }).catch(error => { 
        // commit('SET_ERRORS', error.response.data.errors) 
      })
  },
  
  deleteCalendar({ commit }, id) { 
    axios.delete('account/calendar/' + id) 
      .then(res => { 
        commit('SET_CALENDAR_DELETED', id) 
      }).catch(error => { 
        console.log(error)
      })
  }
}

export default actions