import axios from '@/axios.js'
import store from '../index';
// import router from '../../router/index';

const actions = {
  addCredit({ commit }, value) {  
    axios.post('account/credits', value)
      .then(response => { 
        commit('SET_CREDIT', response.data.credit)    
        
        if(store.state.moduleCredit.errors !== null){
          commit('SET_ERRORS', null)  
        } 

      }).catch(error => {

        console.log(error)
        // commit('SET_ERRORS', error.response.data.errors) 
      })
  },

  getCredits({ commit }) { 
    return new Promise((resolve, reject) => {
      axios.get('account/credits')
      .then(res => { 
        // commit('SET_DEBITS', res.data.data) 
        resolve(res)
      }).catch(error => {
  
        console.log(error)
        // commit('SET_ERRORS', error.response.data.errors) 
      }) 
    }) 

  },

  findCredit(context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`account/credits/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  editCredit({ commit }, value) {   
    axios.post('account/credits/' + value.id, value.data)
      .then(response => {
        commit('SET_CREDIT_UPDATED', response.data.credit)    
        
        if(store.state.moduleCredit.errors !== null){
          commit('SET_ERRORS', null)  
        } 
      
        // router.replace({ name: 'posts' });
  
      }).catch(error => { 
        // commit('SET_ERRORS', error.response.data.errors) 
      })
  },
  
  deleteCredit({ commit }, id) { 
    axios.delete('account/credits/' + id) 
      .then(res => { 
        commit('SET_CREDIT_DELETED', id) 
      }).catch(error => { 
        console.log(error)
      })
  }
}

export default actions